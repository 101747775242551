export const MediaSidebarData = [
    {
        title: "My Account",
        icon: "bi-person-fill",
        childrens: [
            {
                title: "Profile",
                icon: "bi-person-fill",
                 path: "/my-profile"
            },
            {
                title: "Login & Security",
                icon: "bi-house-fill",
                path: "/edit-profile"
            },
            {
                title: "Orders",
                icon: "bi-house-fill",
                path: "/my-orders"
            },
            {
                title: "Addresses",
                icon: "bi-info-circle-fill",
                path: "/address"
            },
            {
                title: "Wishlist",
                icon: "bi-info-circle-fill",
                path: "/wishlist"
            },


        ]
    },
    {
        title: "Categories",
        icon: "bi bi-list-task",
        childrens: [
            {
                title: "Chairs",
                
                childrens: [
                    {
                        title: "Office Chairs",
                        path: "/office-chair",
                 
                    },
                    {
                        title: "Waiting Chairs",
                        path: "/waiting-chair",
                  
                    },
                    {
                        title: "Gaming Chairs",
                        path: "/gaming-chair",
                
                    },

                ]    
            },

            {
                title: "Tables",
               
                childrens: [
                    {
                        title: "Coffee Tables",
                        path: "/coffee-table",
                    
                    },
                    {
                        title: "Reception Desks",
                        path: "/reception-desk",
                    
                    },
       
          

                ]   
            },
            {
                title: "Office Desks",
                path: "/office-desk",
         
            },
            {
                title: "Waiting Benches",
                path: "/waiting-benche",
           
            },

            {
                title: "Bar Stools",
                path: "/bar-stool",
            
            },

            {
                title: "Coat Hanger",
                path: "/coat-hanger",
            
            },
            {
                title: "Office Sofas",
                path: "/office-sofa",
            
            },
            {
                title: "Work Stations",
                path: "/work-station",
            
            },
            {
                title: "Bookshelves",
                path: "/bookshelf",
            
            },
            {
                title: "Fireproof Safes",
                path: "/fireproof-safe",
            
            },
            {
                title: "Office Cabinet",
                path: "/office-cabinet",
            
            },



        ]
    },

    {
        title: "FAQ",
        icon: "bi-question-circle-fill",
        path: "/faq"
    },
    {
        title: "Contact Us",
        icon: "bi-question-circle-fill",
        path: "/contact"
    },
    {
        title: "Logout",
        path: "/logout"
    }
]
