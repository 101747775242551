import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { GrFormNext } from "react-icons/gr";

const BreadCrumb3 = (props) => {
  const { title } = props;

  const productState = useSelector((state) => state?.product?.singleproduct);

  const category = productState?.category || "Product"; // Fallback to "Product" if category is not available
  const formattedCategory = category.toLowerCase().split(" ").join("-"); // Convert to lowercase and replace spaces with hyphens

  const categoryRoute = `/${formattedCategory}`; // Dynamic route based on the formatted category

  return (
    <div className="breadcrumb mb-0 py-4 px-3">
      <div className="container-xxl">
        <div className="row">
          <div className="col-12">
            <span className="text-align-start mb-0">
              <Link to="/" className="text-dark">
                Home&nbsp;
              </Link>
              <span><GrFormNext />&nbsp;</span>
              
              <Link to={categoryRoute} className="text-dark">
                {category}&nbsp;
              </Link>
              <span><GrFormNext />&nbsp;{title}</span>
              
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BreadCrumb3;
