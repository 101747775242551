import React, { useEffect, useState } from 'react';  // Import React hooks
import { useSelector, useDispatch } from 'react-redux';  // Import Redux hooks
import { useLocation } from 'react-router-dom';  // Import useLocation for getting URL parameters
import ReactPaginate from 'react-paginate';  // Import pagination component
import ProductCard from '../components/ProductCard';  // Import ProductCard component
import Meta from '../components/Meta';  // Import Meta component for page metadata
import BreadCrumb from '../components/BreadCrumb';  // Import BreadCrumb component
import Container from '../components/Container';  // Import Container component
import { getAllProducts } from '../features/products/productSlice';  // Import the getAllProducts action
import { useMediaQuery } from "react-responsive";
import SearchCard from '../components/SearchCard';
import { IoFilter } from "react-icons/io5";
import { MdKeyboardBackspace } from "react-icons/md";
import { Link} from "react-router-dom";

const MaterialPage = () => {
    const [grid, setGrid] = useState(4);  // Grid layout for products
    const productState = useSelector((state) => state?.product?.product);  // Fetch all products from Redux store
    const [filteredProducts, setFilteredProducts] = useState([]);  // Store the filtered products
    const [currentPage, setCurrentPage] = useState(0);  // Pagination: current page
    const itemsPerPage = 12;  // Number of items per page
    const dispatch = useDispatch();  // For dispatching Redux actions
    const location = useLocation();  // Access URL query parameters
    const [materials, setMaterials] = useState([]);  // Store available materials
    const [categories, setCategories] = useState([]);  // Store available categories
    const isDesktop = useMediaQuery({ minWidth: 768 });  // Check if on desktop
    const [sort,setSort]=useState(null)
    const [minPrice,setMinPrice]=useState(null)
    const [maxPrice, setMaxPrice] = useState(null)
    const [selectedMaterial, setSelectedMaterial] = useState('');  // Store the selected material
    const [tag, setTag] = useState(null)
    const [category, setCategory] = useState(null)

    useEffect(() => {
      dispatch(getAllProducts());
    }, [dispatch]);
  
    useEffect(() => {
      let newCategories = new Set();
      let newMaterials = new Set();
  
      productState.forEach((product) => {
        newCategories.add(product.category);
        newMaterials.add(product.material);
      });
  
      setCategories([...newCategories]);
      setMaterials([...newMaterials]);
    }, [productState]);
  
    useEffect(() => {
      if (selectedMaterial) {
        filterByMaterial(selectedMaterial);  // Filter by selected material
      } else {
        setFilteredProducts(productState);  // If no material is selected, show all products
      }
    }, [selectedMaterial, productState]);
  
    const filterByMaterial = (material) => {
      const filtered = productState.filter(
        (product) => product.material.toLowerCase() === material.toLowerCase()
      );
      setFilteredProducts(filtered);
    };
  
    const handlePageChange = ({ selected: selectedPage }) => {
      setCurrentPage(selectedPage);
    };
  
    const startIndex = currentPage * itemsPerPage;
    const currentItems = filteredProducts.slice(startIndex, startIndex + itemsPerPage);
  
    const pageCount = Math.ceil(filteredProducts.length / itemsPerPage);  // Pagination count based on filtered products
  
    const [activeCategory, setActiveCategory] = useState(null);
    const [hoveredCategory, setHoveredCategory] = useState(null); // State to track hovered category
    const [hoveredMaterial, setHoveredMaterial] = useState(null);
  
    const handleCategoryClick = (category) => {
      const formattedCategory = category.toLowerCase().split(" ").join("-");
      setActiveCategory(formattedCategory); // Set the active category
      window.location.href = `/${formattedCategory}`;
    };
  
    const handleMaterialClick = (material) => {
      setSelectedMaterial(material);  // Update the selected material in the state
    };


    useEffect(() => {
        getProducts();
      }, [sort,tag,category,minPrice,maxPrice]);
      const getProducts = () => {
        dispatch(getAllProducts({sort,tag,category,minPrice,maxPrice}));
      };

    const [showFilter, setShowFilter] = useState(false); 

    const [tempMinPrice, setTempMinPrice] = useState(minPrice || "");
  const [tempMaxPrice, setTempMaxPrice] = useState(maxPrice || "");
  
  const handleMinPriceChange = (e) => {
    setTempMinPrice(e.target.value);
  };
  
  const handleMaxPriceChange = (e) => {
    setTempMaxPrice(e.target.value);
  };
  
    const toggleFilter = () => {
      setShowFilter(prev => {
        const newShowFilter = !prev;
        document.body.style.overflow = newShowFilter ? "hidden" : "auto";
        return newShowFilter;
      });
    };
  
    const handleBackButtonClick = () => {
      setShowFilter(false);
      document.body.style.overflow = "auto"; 
    };
    
  
    const applyFilters = () => {
      setMinPrice(tempMinPrice);
      setMaxPrice(tempMaxPrice);
      setShowFilter(false);
      document.body.style.overflow = "auto"; 
    
      dispatch(getAllProducts({ minPrice: tempMinPrice, maxPrice: tempMaxPrice,  }));
    };
    
    
    const resetFilters = () => {
      setTempMinPrice("");
      setTempMaxPrice("");
      setMinPrice(null);
      setMaxPrice(null);
      setTag(null);
      setCategory(null);
      
      getProducts();
    };
    
    const renderFilter = () => {
      return (
        <div className="col-14 shop-by-media">
          <div className="filter-back-btn-wrapper">
            <Link 
              className="filter-btn-container1"
              onClick={handleBackButtonClick}>
              <MdKeyboardBackspace className="filter-back-icon" />
              <h1>Filter</h1>
            </Link>
          </div>
          <div className="filter-card mb-3">
            <h3 className="filter-title">Shop By Categories</h3>
            <div>
              <ul className="ps-0">
                {categories.map((item, index) => {
                  const formattedCategory = item.toLowerCase().split(" ").join("-");
                  const isActive = location.pathname.includes(formattedCategory);
    
                  return (
                    <li
                      key={index}
                      onClick={() => handleCategoryClick(item)}
                      onMouseEnter={() => setHoveredCategory(formattedCategory)}
                      onMouseLeave={() => setHoveredCategory(null)}
                      style={{
                        color:
                          isActive || hoveredCategory === formattedCategory
                            ? "#febd69"
                            : "inherit",
                        cursor: "pointer",
                        transition: "color 0.3s",
                      }}
                    >
                      {item}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="filter-card mb-3">
            <h3 className="filter-title">Filter By</h3>
            <div>
              <h5 className="sub-title">Price</h5>
              <div className="d-flex align-items-center gap-10">
                <div className="form-floating">
                <input
                  type="number"
                  className="form-control"
                  id="floatingInput"
                  placeholder="From"
                  value={tempMinPrice}
                  onChange={handleMinPriceChange}
                />
  
                  <label htmlFor="floatingInput">From</label>
                </div>
                <div className="form-floating">
                <input
                  type="number"
                  className="form-control"
                  id="floatingInput1"
                  placeholder="To"
                  value={tempMaxPrice}
                  onChange={handleMaxPriceChange}
                />
                  <label htmlFor="floatingInput1">To</label>
                </div>
              </div>
            </div>
          </div>
          <div className="filter-card mb-3">
            <h3 className="filter-title">Filter By Materials</h3>
            <div>
              <ul className="ps-0">
                {materials.map((item, index) => {
                  const formattedMaterial = item.toLowerCase().split(" ").join("-");
                  const isActive = location.pathname.includes(formattedMaterial);
    
                  return (
                    <li
                      key={index}
                      onClick={() => handleMaterialClick(item)}
                      onMouseEnter={() => setHoveredMaterial(formattedMaterial)}
                      onMouseLeave={() => setHoveredMaterial(null)}
                      style={{
                        color:
                          isActive || hoveredMaterial === formattedMaterial
                            ? "#febd69"
                            : "inherit",
                        cursor: "pointer",
                        transition: "color 0.3s",
                      }}
                    >
                      {item}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="filter-btn-container">
            <button onClick={resetFilters}>RESET</button>
            <button onClick={applyFilters}>SHOW</button>
          </div>
        </div>
      );
    };
  

  return (
    <>
      <Meta title={"Materials"} />
      <BreadCrumb title="Shop By Materials" />
      <Container class1="search-wrapper home-wrapper-2 py-5">
        <div className="row laptop-row">
          <div className="col-3">
          <div className="filter-card  mb-3">
              <h3 className="filter-title">Shop By Categories</h3>
              <div>
              <ul className="ps-0">
      {categories.map((item, index) => {
        const formattedCategory = item.toLowerCase().split(" ").join("-");
        const isActive = location.pathname.includes(formattedCategory);

        return (
          <li
            key={index}
            onClick={() => handleCategoryClick(item)}
            onMouseEnter={() => setHoveredCategory(formattedCategory)} // Set hovered category
            onMouseLeave={() => setHoveredCategory(null)} // Reset hovered category
            style={{
              color:
                isActive || hoveredCategory === formattedCategory
                  ? "#febd69" // Apply hover color or active color
                  : "inherit",
              cursor: "pointer",
              transition: "color 0.3s", // Smooth transition effect
            }}
          >
            {item}
          </li>
        );
      })}
    </ul>
              </div>
            </div>
            <div className="filter-card mb-3">
              <h3 className="filter-title">Filter By</h3>
              <div>
               <h5 className="sub-title">Price</h5>
                <div className="d-flex align-items-center gap-10">
                  <div className="form-floating">
                    <input
                      type="number"
                      className="form-control"
                      id="floatingInput"
                      placeholder="From"
                      onChange={(e)=>setMinPrice(e.target.value)}
                    />
                    <label htmlFor="floatingInput">From</label>
                  </div>
                  <div className="form-floating">
                    <input
                      type="number"
                      className="form-control"
                      id="floatingInput1"
                      placeholder="To"
                      onChange={(e)=>setMaxPrice(e.target.value)}
                    />
                    <label htmlFor="floatingInput1">To</label>
                  </div>
                </div>
              </div>
            </div>
            <div className="filter-card  mb-3">
              <h3 className="filter-title">Filter By Materials</h3>
              <div>
              <ul className="ps-0">
      {materials.map((item, index) => {
        const formattedMaterial = item.toLowerCase().split(" ").join("-");
        const isActive = selectedMaterial === item;

        return (
          <li
            key={index}
            onClick={() => handleMaterialClick(item)}
            onMouseEnter={() => setHoveredMaterial(formattedMaterial)}
            onMouseLeave={() => setHoveredMaterial(null)}
            style={{
              color:
                isActive || hoveredMaterial === formattedMaterial
                  ? "#febd69" 
                  : "inherit",
              cursor: "pointer",
              transition: "color 0.3s", 
            }}
          >
            {item}
          </li>
        );
      })}
    </ul>
              </div>
            </div>
          </div>
          <div className="col-9 search-container  position-relative">
          <div className="filter-sort-grid filter-container justify-content-between d-flex sort-by mb-4">
              <div className="d-flex  justify-content-between align-items-center">
                <div className="d-flex  align-items-center gap-10">
                  <p className="mb-0 d-block" style={{ width: "100px" }}>
                    Sort By:
                  </p>
                  <select
                    name=""
                    defaultValue={"manula"}
                    className="form-control form-select"
                    id=""
                    onChange={(e)=>setSort(e.target.value)}
                  >
                    <option value="title">Alphabetically, A-Z</option>
                    <option value="-title">
                      Alphabetically, Z-A
                    </option>
                    <option value="price">Price, low to high</option>
                    <option value="-price">Price, high to low</option>
                  </select>
                </div>
     
              </div>
              {!isDesktop && (
              <div className="filter-wrapper">
            <button onClick={toggleFilter}>
               <IoFilter className="filter-icon"/>
            </button>
          </div>
           )}
    
            </div>
            <div className="products-list   pb-5">
            {isDesktop ? (

            <div className={`d-grid ${grid === 12 ? 'd-flex' : ''} flex-wrap`}>
  {currentItems.map((product, index) => (
    <ProductCard key={index} data={product} grid={grid} />
  ))}
       </div>):(
         <div className="search-list">
         {currentItems.map((product, index) => (
           <SearchCard key={index} data={product}  />
         ))}

       </div>
)}

            </div>
        <div className="d-flex  paginate-container1 position-absolute">
     <div>
        <ReactPaginate
        breakLabel="..."
        nextLabel=" >"
        onPageChange={handlePageChange}
        pageRangeDisplayed={3}
        pageCount={pageCount}
        previousLabel="< "
        renderOnZeroPageCount={null}
        containerClassName="pagination1"
        pageLinkClassName="page-num"
        previousLinkClassName="page-num"
        nextLinkClassName="page-num"
        activeLinkClassName="active"
      />
      </div>
        </div>
        {showFilter && renderFilter()} 

          </div>
        </div>
      </Container>
    </>
  );
};

export default MaterialPage;
