import React, { useEffect, useState, useRef } from 'react';
import { MdAdd } from "react-icons/md";

const Counter = () => {
    const countersRef = useRef(null);
    const [activated, setActivated] = useState(false);
    const duration = 3000; // Desired duration in milliseconds (e.g., 3 seconds)

    useEffect(() => {
        const handleScroll = () => {
            if (!countersRef.current) return;

            const container = countersRef.current;
            const containerTop = container.offsetTop;
            const containerHeight = container.offsetHeight;
            const scrollTop = window.pageYOffset;

            if (scrollTop > containerTop - containerHeight - 200 && !activated) {
                setActivated(true);
            } else if ((scrollTop < containerTop - containerHeight - 500 || scrollTop === 0) && activated) {
                setActivated(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [activated]);

    useEffect(() => {
        if (activated && countersRef.current) {
            const counters = countersRef.current.querySelectorAll(".counter span[data-count]");

            counters.forEach(counter => {
                counter.innerText = 0;
                let count = 0;
                const target = parseInt(counter.dataset.count);
                const increment = target / (duration / 20); // 20ms per step

                const updateCount = () => {
                    count += increment;
                    if (count < target) {
                        if (counter.dataset.type === "clients") {
                            counter.innerText = `${Math.ceil(count)} `;
                        } else {
                            counter.innerText = Math.ceil(count);
                        }
                    } else {
                        if (counter.dataset.type === "clients") {
                            counter.innerText = `${target} `;
                        } else if (counter.dataset.type === "years") {
                            counter.innerText = `${target}`;
                        }
                        clearInterval(counterInterval);
                    }
                };

                const counterInterval = setInterval(updateCount, 20); // Update every 20ms
            });
        }
    }, [activated]);

    return (
        <>
            <div className='clients-container'>
                <div className='d-flex flex-column elementor'>
                    <div className='clients-content'>
                        <h1>We work through every aspect at the planning</h1>
                        <b>WE DO IT FOR YOU WITH LOVE</b>
                    </div>
                    <div ref={countersRef} className="counters">
                        <div>
                            <div className="counter">
                                <h1>
                                    <span data-count="2000" data-type="clients">0 </span>
                                </h1>
                                <h3>Satisfied Clients</h3>
                            </div> 
                            <div className="counter">
                                <h1><span data-count="180" data-type="company">0 </span></h1>
                                <h3>Company Work With Us</h3>
                            </div> 
                            <div className="counter">
                                <h1><span data-count="850" data-type="jobs">0</span></h1>
                                <h3>Projects Completed</h3>
                            </div> 
                            <div className="counter">
                                <h1>
                                    <span data-count="20" data-type="years">0</span>
                                    <span className="add-icon"><MdAdd /></span>
                                </h1>
                                <h3>Years Experience</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Counter;
