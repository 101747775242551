import React, { useEffect, useState } from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { BsSearch } from "react-icons/bs";
import compare from "../images/compare.svg";
import wishlist from "../images/wishlist.svg";
import user from "../images/user.svg";
import cart from "../images/cart.svg";
import menu from "../images/menu.svg";
import { useDispatch, useSelector } from "react-redux";
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { getAProduct, getAllProducts } from "../features/products/productSlice";
import { getUserCart } from "../features/user/userSlice";
import {AiOutlineMenu} from "react-icons/ai"
import { MediaSidebarData } from './MediaSidebarData';
import MediaSidebarItem from './MediaSidebarItem';
import {RxCross2} from "react-icons/rx"
import AdvertisementBar from "./AdvertisementBar";
import {FaUserCheck} from "react-icons/fa";
import {FaUser} from "react-icons/fa"
import { BsCart4 } from "react-icons/bs";
import {AiOutlineHeart} from "react-icons/ai"
import {IoCall} from "react-icons/io5"

const Header2 = () => {
  const dispatch = useDispatch();
  const cartState = useSelector(state => state?.auth?.cartProducts)
  const authState = useSelector(state => state?.auth)
  const productState = useSelector(state => state?.product?.product)
  const [productOpt,setProductOpt]=useState([])
  const [paginate, setPaginate] = useState(true);
const navigate=useNavigate()
  const [total, setTotal] = useState(0)
  const getTokenFromLocalStorage = localStorage.getItem("customer")
  ? JSON.parse(localStorage.getItem("customer"))
  : null;

  

 const config2 = {
  headers: {
    Authorization: `Bearer ${
      getTokenFromLocalStorage !== null ? getTokenFromLocalStorage.token : ""
    }`,
    Accept: "application/json",
  },
};
  useEffect(() => {
  dispatch(getUserCart(config2))
},[])
  useEffect(() => {
    let sum=0
    for (let index = 0; index < cartState?.length; index++) {
     sum=sum + (Number(cartState[index].quantity) * Number(cartState[index].price))
      setTotal(sum)
    }
  }, [cartState])
  useEffect(() => {
    let data = []
    for (let index = 0; index < productState.length; index++) {
      const element = productState[index];
      data.push({id:index,prod:element?._id,name:element?.title})
      
    }
    setProductOpt(data)
    
  },[productState])
  

  const handleLogout = () => {
    localStorage.clear()
    window.location.reload()
  }

  const showSideBar = () => {
    setSidebar(!sidebar);
    // Toggle class on body element
    document.body.classList.toggle('sidebar-active', !sidebar);
  };

  const [sidebar, setSidebar] = useState(false);



  return (
    <>

    <div className="w-100 advertisement-bar">
      <AdvertisementBar/>
    </div>
      <header className="header-top-strip2 py-3">
        <div className="container-xxl">
          <div className="row free-shipping">
            <div className="col-6">
              <p className="text-white mb-0">
                Free Shipping Over $100 & Free Returns
              </p>
            </div>
            <div className="col-6">
              <p className="text-end text-white mb-0">
                Hotline:
                <a className="text-white" href="tel:+254 746878028">
                  +254 746878028
                </a>
              </p>
            </div>
          </div>

        </div>
      </header>
      <header className="header-upper2 py-3 fixed-header">
        <div className="container-xxl">
          <div className="row align-items-center">
            <div className="col-2 h-upper-links">
              <div className="electro-logo d-flex align-items-center">

              <AiOutlineMenu className="menu-btn" onClick={showSideBar}/>

   
              <div className={sidebar ? 'sidebar active' : 'sidebar'}>
  <div className='sidebar-header'>
    Toptier Office
    <RxCross2 className='cross-btn'onClick={showSideBar}/>
    
  </div>
       {MediaSidebarData.map((item,index) =>  <MediaSidebarItem key={item.id} item={item} onClick={showSideBar}/>)}
      </div>
      {sidebar && <div className="overlay" onClick={showSideBar}></div>}


              <h2 className="align-items-center w-100  d-flex mb-0">
                <Link to='/' className="text-white -">ElectroCity</Link>
              </h2>
              </div>
              <div className="col-5 h-upper-links2">
              <div className="header-upper-links2 gap-10  d-flex align-items-center ">
        
  
              <div className="logins">
                <Link
                  to={authState?.user === null ? "/login" : "/my-profile"}
                  className="d-flex login-container align-items-center text-white"
                >
                  {authState?.user === null ? (
                    <>
                      <p className="mb-0 login-auth">
                        Log in 
                      </p>
                      <FaUser  className="login-icon1"/>
                    </>
                  ) : (
                    <>
                      <p className="mb-0 login-auth">{authState?.user?.firstname}</p>
                      <FaUserCheck  className="login-icon"/>
                    </>
                  )}
                </Link>
              </div>

            
              </div>
            </div>
            </div>
            
            <div className="col-5">
              <div className="input-group">
              <Typeahead
        id="pagination-example"
                  onPaginate={() => console.log('Results paginated')}
                  onChange={(selected) => {
                    navigate(`/product/${selected[0]?.prod}`)
                    dispatch(getAProduct(selected[0]?.prod))
                  }}
        options={productOpt}
                  paginate={paginate}
                  labelKey={"name"}
                  minLength={2}
        placeholder="Search for Properties here..."
      />
                <span className="input-group-text p-3" id="basic-addon2">
                  <BsSearch className="fs-6" />
                </span>
              </div>
            </div>
            <div className="col-5 h-upperlinks1  ">
              <div className="header-upper-links  d-flex align-items-center ">
 
          <div className=" d-flex contact-container align-items-center">
            <p className="d-flex mb-0 text-white align-items-center">Contact Us</p>
          </div>

                <div className="logins">
                <Link
                  to={authState?.user === null ? "/login" : "/my-profile"}
                  className="d-flex login-container align-items-center text-white"
                >
                  {authState?.user === null ? (
                    <>
                      <p className="mb-0 login-auth">
                        Log in 
                      </p>
                      <FaUser  className="login-icon1"/>
                    </>
                  ) : (
                    <>
                    <FaUserCheck  className="login-icon"/>
                      <p className="mb-0 login-auth">{authState?.user?.firstname}</p>
                    </>
                  )}
                </Link>
              </div>

              </div>

            </div>
          </div>
        </div>
      </header>
      <header className="header-bottom2 py-3">
        <div className="container-xxl">
          <div className="row">
            <div className="col-12">
              <div className="menu-bottom  d-flex">
  
                <div className="menu-links w-50 ">
                  <div className="d-flex gap-3 align-items-center menu-links-nav">
                    <NavLink to="/">Residential Sales</NavLink>
                    {/*<NavLink to="/product">Our Store</NavLink>*/}
                    <NavLink to="/">Commercial Sales</NavLink>

                    <NavLink to="/my-orders">To Rent</NavLink>
                    <NavLink to="/contact">Services</NavLink>

                    <button onClick={handleLogout} className="border border-0 bg-transparent text-white text-uppercase" type="button">Logout</button>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header2;
