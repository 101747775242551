import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import newsletter from "../images/newsletter.png";
import { subscribeNewsletter } from "../features/user/userSlice";
import { useMediaQuery } from "react-responsive";
import { FaFacebook } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import { BsSendFill } from "react-icons/bs";


const Footer = () => {
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();
  const { message } = useSelector((state) => state.user || { message: null });
  const isDesktop = useMediaQuery({ minWidth: 768 });

  const handleSubscribe = (e) => {
    e.preventDefault();
    if (email) {
      dispatch(subscribeNewsletter(email)); // Dispatch the action with the email
      setEmail(""); // Optionally clear the input after submission
    }
  }; 
  return (
    <>
      <footer className="py-4 footer-el-top ">
        <div className="container-xxl">
          <div className="d-flex footer-one align-items-center">
            <div className="col-5">
              <div className="footer-top-data d-flex gap-30 align-items-center">
              <BsSendFill className="send-icon"/>
                <h2 className="mb-0 text-white footer-one-title">Sign Up for Newsletter</h2>
              </div>
            </div>
            <div className="col-7">
              <div className="input-group">
              <form onSubmit={handleSubscribe} className="input-group">
                <input
                  type="email"
                  className="form-control py-1"
                  placeholder="Your Email Address"
                  aria-label="Your Email Address"
                  aria-describedby="basic-addon2"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <button type="submit" className="input-group-text p-2" id="basic-addon2">
                  Subscribe
                </button>
              </form>
              {message && <div className="error-message text-danger mt-2">{message}</div>}

              </div>
            </div>
          </div>
        </div>
      </footer>
      <footer className="py-4 footer-el px-3">
        <div className="container-xxl">
          <div className="row footer-2">
            { isDesktop ? (
              <>
<div className="left-footer d-flex justify-content-between">
  <div className="col-3">
    <h4 className="text-white mb-4">Information</h4>
    <div className="footer-link d-flex flex-column">
      <a href="/privacy-policy" className="text-white py-2 mb-1">
        Privacy Policy
      </a>
      <a href="/refund-policy" className="text-white py-2 mb-1">
        Return & Refund Policy
      </a>
      <a href="/shipping-policy" className="text-white py-2 mb-1">
        Delivery & Shippping Policy
      </a>
      <a href="/term-conditions" className="text-white py-2 mb-1">
        Terms & Conditions
      </a>
    </div>
  </div>
  <div className="col-3">
    <h4 className="text-white mb-4">Account</h4>
    <div className="footer-link d-flex flex-column">
      <a href="/about" className="text-white py-2 mb-1">About Us</a>
      <a href="/faqs" className="text-white py-2 mb-1">FaQs</a>
      <a href="/contact" className="text-white py-2 mb-1">Contact</a>
    </div>
  </div>
  <div className="col-3 ">
    <h4 className="text-white mb-4">Quick Links</h4>
    <div className="footer-link d-flex flex-column">
      <a href="/office-chair" className="text-white py-2 mb-1">Office Chairs</a>
      <a href="/office-desk" className="text-white py-2 mb-1">Office Desks</a>
      <a href="/bar-stool" className="text-white py-2 mb-1">Bar Stools</a>
      <a href="/waiting-bench" className="text-white py-2 mb-1">Waiting Benches</a>
    </div>
  </div>
  <div className="col-3">
    <div className="contacts">
      <h4 className="text-white  mb-4">Contact Us</h4>
      <address className="text-white fs-6">
        Ngara Rd, 00600<br /> Nairobi, Kenya <br />
       
      </address>
      <a href="tel:+254 113 886 482" className="mt-3 d-block mb-1 text-white">
      +254 113 886 482
      </a>
      <a href="mailto:electrocity@gmail.com" className="mt-2 d-block mb-0 text-white">
        bestsellersshopke@gmail.com
      </a>
    </div>
    <div className="social_icons d-flex align-items-center gap-30 mt-4">
      <a className="text-white" href="#">
        <FaInstagram className=" social-icon" />
      </a>
      <a className="text-white" href="#">
        <FaXTwitter className=" social-icon" />
      </a>
      <a className="text-white" href="https://fb.me/6Qf0mqFaH">
        <FaFacebook className=" social-icon" />
      </a>
    </div>
  </div>
</div>

              </>
            ): (
              <>
              <div className="media-footer">
              <div className="col-3">
                <h4 className="text-white mb-4">Information</h4>
                <div className="footer-link d-flex flex-column">
                  <a href="/privacy-policy" className="text-white py-2 mb-1">
                    Privacy Policy
                  </a>
                  <a href="/refund-policy" className="text-white py-2 mb-1">
                    Return & Refund Policy
                  </a>
                  <a href="/shipping-policy" className="text-white py-2 mb-1">
                    Delivery & Shippping Policy
                  </a>
                  <a href="/term-conditions" className="text-white py-2 mb-1">
                    Terms & Conditions
                  </a>
                </div>
              </div>
       
            <div className="col-3">
            <h4 className="text-white mb-4">Account</h4>
            <div className="footer-link d-flex flex-column">
              <a href="/about" className="text-white py-2 mb-1">About Us</a>
              <a href="/faqs" className="text-white py-2 mb-1">FaQs</a>
              <a href="/contact" className="text-white py-2 mb-1">Contact</a>
            </div>
 
            </div>
    

   
              </div>
             <div className="social-icons-container">
             <div className="social_icons d-flex align-items-center gap-30 mt-4">

          <a className="text-white" href="#">
          <FaInstagram className="fs-4 social-icon" />
          </a>
          <a className="text-white" href="#">
          <FaXTwitter  className="fs-4 social-icon" />
          </a>
          <a className="text-white" href="https://fb.me/6Qf0mqFaH">
            <FaFacebook className="fs-4 social-icon" />
          </a>
        </div>
             </div>
              </>
            )}
 



            </div>
            
          </div>
       
      </footer>
      <footer className="py-4 footer-el-bottom">
        <div className="container-xxl">
          <div className="row">
            <div className="col-12">
              <p className="text-center mb-0 text-white">
                &copy; {new Date().getFullYear()} The Best Sellers Shop Ltd
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
  };

export default Footer;