import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { getRecentlyViewedProducts } from '../features/user/userSlice';

function countWords(text) {
  return text.split(/\s+/).filter((word) => word !== '').length;
}

const RecentlyViewedProducts2 = () => {
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const recentlyViewed = useSelector((state) => state.auth.recentlyViewed);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getCustomerfromLocalStorage = localStorage.getItem("customer")
    ? JSON.parse(localStorage.getItem("customer"))
    : null;

  const userId = getCustomerfromLocalStorage ? getCustomerfromLocalStorage._id : null;

  useEffect(() => {
    if (userId) {
      dispatch(getRecentlyViewedProducts(userId));
    }
  }, [dispatch, userId]);

  return (
    <section className="related-products-wrapper flex-column justify-content-center d-flex w-100">
      <div className={`related-products-collection${isDesktop ? ' row w-100' : ''}`}>
      {recentlyViewed && recentlyViewed.map((product, index) => {
          // Calculate the percentage difference for each product
          const percentageDifference = Math.round(
            ((product.previousPrice - product.price) / product.previousPrice) * 100
          );

          return (
            <div
              className="product-card position-relative"
              key={index}
            >
              <div className="product-image">
                <img
                  src={product?.images[0].url}
                  className="img-fluid mx-auto"
                  alt="product image"
                  width={160}
                  onClick={() => { window.location.href = '/product/' + product?._id; }}   
                  />
              </div>
              <div className="percentage-difference">
                -{percentageDifference}%
              </div>
              <div className="product-details">
                <h6 className="brand">{product?.brand}</h6>
                <h5 className="product-title">{product?.title}</h5>
                <p className="previous-price">KES {product.previousPrice.toLocaleString()}</p>
                <p className="price">KES {product?.price.toLocaleString()}</p>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default RecentlyViewedProducts2;
