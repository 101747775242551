import React, { useState, useEffect } from 'react';

const AdvertisementBar = () => {
  const [currentSpan, setCurrentSpan] = useState(1);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setCurrentSpan(currentSpan === 1 ? 2 : 1);
    }, 3000);

    return () => {
      clearTimeout(timeout);
    };
  }, [currentSpan]);

  return (
    <div className="advertisement-bar">
      {currentSpan === 1 && (
        <span>
          Orders up to : 
          <span className="animated-text">
            <b className="slanted-background">50% OFF!</b>
          </span>
        <p className='m-0 desktop-only'>Call +254 113886482 to order</p>  
        </span>
      )}
      {currentSpan === 2 && (
      <span>
       Discover premium comfort with us!
     </span>
      )}
    </div>
  );
};

export default AdvertisementBar;
